.page {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: var(--gray200);
  overflow: hidden;
}

.precontent {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing1000);
  padding: var(--spacing1000);
  z-index: 1;

  &:empty {
    display: none;
  }
}

.precontentSeparator {
  width: 100%;
  border-top: 1px solid var(--gray500);
  border-bottom: 0;
}

.content {
  position: relative;
  padding-top: var(--spacing0250);
  padding-left: var(--spacing1000);
  padding-right: var(--spacing1000);
  /* only used to let space for the Freshdesk widget button */
  padding-bottom: var(--spacing4000);
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;

  &.topMargin {
    margin-top: var(--spacing1000);
  }

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.information {
  margin: 0;
  padding: var(--spacing1000);
  min-height: 0;
}

@media only screen and (--max-width-sm) {
  .page {
    overflow: auto;
  }
  .content {
    overflow: unset;
  }
}
