.row {
  background-color: var(--gray300);
  &.bgTransparent {
    background-color: transparent;
    & .cell {
      background-color: transparent;
    }
  }
}

.cell {
  background-color: var(--gray300);
  height: 50px;
  padding: 0 0.5em;
  text-align: left;
  font-weight: normal;

  &.withBorderLeft {
    border-left: 1px solid var(--gray500);
  }

  &.withBorderRight {
    border-right: 1px solid var(--gray500);
  }

  &.center,
  &.center * {
    text-align: center;
  }

  &.right,
  &.right * {
    text-align: right;
  }

  /* Only set to align with table's rows which display a left border on hover */
  &:first-child {
    border-left: 5px solid var(--gray300);
  }
}

.row.minimal {
  & .cell {
    height: auto;
    font-size: 0.875rem;

    &:first-child {
      border-left-color: transparent;
    }
  }
}

.smallCell {
  width: 50px;
  max-width: 50px;
  box-sizing: border-box;
}

.exportCell {
  border-left: 2px solid var(--gray400);
}

.exportBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleWrapper {
  display: inline-flex;
  align-items: center;
  & .infoTooltip {
    margin-left: var(--spacing0500);
    font-size: var(--iconXS);
  }

  & .subTitle {
    margin-left: var(--spacing0500);
  }
}
