.container {
  --footer-height: 70px;
  .content {
    padding-bottom: var(--footer-height);
    & .contentInner {
      width: 824px;
      margin: auto;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: var(--footer-height);
    background: var(--white);
    border-top: 1px solid var(--gray300);
    display: flex;
    align-items: center;
    & .footerInner {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: var(--spacing0750);
      width: 824px;
      margin: auto;
      & .footerExtraWrapper {
        flex: 1 0;
      }
    }
  }
}

@media screen and (--max-width-md) {
  .container {
    .content {
      & .contentInner {
        width: auto;
        padding: 0 var(--spacing1000);
      }
    }

    .footer {
      & .footerInner {
        width: 100%;
        padding: 0 var(--spacing1000);
      }
    }
  }
}

@media screen and (--max-width-sm) {
  .footer {
    position: fixed;
  }
}
