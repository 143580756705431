.header {
  margin-bottom: var(--spacing1000);
}

.name {
  margin-bottom: var(--spacing0500);
}

.address {
  margin-bottom: var(--spacing0500);
}

.companyCurrentData {
  padding: var(--spacing1000) 0;
  margin-bottom: var(--spacing1000);
}

.reviewCard {
  display: flex;
  border: 1px solid var(--gray900);
  border-radius: var(--radiusS);
  padding: var(--spacing1000);
  & .changeIndicator {
    font-weight: var(--fontWeightSemibold);
  }
  & .actionButtons {
    gap: var(--spacing0750);
    display: flex;
    align-items: flex-start;
    justify-items: flex-end;
    flex: 1;
    justify-content: end;
  }
}

@media screen and (--max-width-sm) {
  .reviewCard {
    flex-direction: column;
    gap: var(--spacing2000);
    & .actionButtons {
      width: 100%;
      align-items: center;
      justify-items: center;
      justify-content: center;
      & .actionButton {
        width: 30%;
      }
    }
  }
}
