.load {
  width: 70%;
  min-width: 0;
  height: 1em;
  padding: 0;
}

.withBorderLeft {
  border-left: 1px solid var(--gray500);
}

.withBorderRight {
  border-right: 1px solid var(--gray500);
}
