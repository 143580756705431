.contentClassTabs {
  padding-top: 0;
  & .tabMenu {
    margin-top: var(--spacing1000);
    padding: 0 !important;
  }
}

.tabsContent {
  padding-top: 20px;
}
