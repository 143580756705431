.container {
  display: flex;
  & span:not(:last-of-type)::after {
    content: '';
    border-left: 1px solid var(--gray500);
    margin: 0 var(--spacing1000);
  }
  flex-wrap: wrap;
  gap: var(--spacing0500) 0px;
}
