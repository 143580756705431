.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: var(--spacing1000);

  & > * {
    flex: 1 0;
    margin-bottom: 0;
  }

  &.compact {
    & .taxCodeField {
      flex: unset;
      width: 200px;
      padding: var(--spacing0500) 0;
    }

    & .taxAmountField {
      width: 130px;
      padding: var(--spacing0500) 0;
    }
  }
}

@media screen and (--max-width-sm) {
  .container {
    &.compact {
      & .taxCodeField {
        width: 100%;
      }
      & .taxAmountField {
        width: 100%;
      }
    }
  }
}
