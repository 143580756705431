.fieldset {
  padding: 0;
  border: none;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--gray300);
    margin-bottom: var(--spacing1000);
    padding-bottom: var(--spacing0500);
  }

  & > legend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing0750);
    width: 100%;
    padding: 0;
    margin-bottom: var(--spacing1000);
  }
}
