.container {
  color: var(--ink600);
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.inner {
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing2000);
}

.title {
  font-size: 2.3em;
  margin-bottom: 1em;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.text {
  font-size: 1em;
  margin-bottom: 2em;
}
