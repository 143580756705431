.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0500);
  background-color: var(--brightBlue100);
  padding: var(--spacing1000);

  &.dark {
    background-color: var(--gray900);
    border-top: 1px solid var(--gray300);
  }
}

.contact {
  display: flex;
  flex-direction: column;
  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
