.container {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;

  & .loader {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .sideViewWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    z-index: 1;

    & .sideView {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      overflow-x: visible;
      overflow-y: auto;
      width: 300px;
      background: var(--gray200);
      box-shadow: 3px 0 5px var(--black020);
      transition: width 200ms linear;
      overflow-x: hidden;
      overflow-y: auto;
      border-left: 1px solid var(--gray300);
      box-sizing: border-box;
    }

    & .closeBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: -23px;
      width: 22px;
      height: 40px;
      background: var(--primaryBg);
      cursor: pointer;
      box-shadow: 3px 0 5px var(--black020);
      transition: right 200ms linear;
      & .icon {
        color: var(--textOnPrimaryBg);
        font-size: 1.5rem;
      }
    }
  }
  & .scrollableArea {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  & .sideViewWrapper.closed {
    & .sideView {
      width: 0;
    }
  }
  & .list {
    overflow-y: auto;
    flex-grow: 1;
  }

  & .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & .header {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      background-color: var(--gray300);
      height: 70px;

      & .headerLeft {
        display: flex;
        flex: 1 1 50%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        overflow: hidden;
      }

      & .headerRight {
        display: flex;
        flex: 1 1 50%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
      }

      & .flashBanner {
        bottom: 0;
      }
    }

    & .content {
      flex: 1;
      display: flex;
      flex-direction: row;
      overflow: hidden;

      & .contentLeft {
        display: flex;
        flex-direction: column;
        flex: 1 1 50%;
        background: var(--white);
        overflow: auto;
      }

      & .contentRight {
        display: flex;
        flex-direction: column;
        flex: 1 1 50%;
        background: var(--gray300);
        border-left: 1px solid var(--gray300);
        overflow: auto;
      }
    }
  }
}

/* tablet */
@media only screen and (--max-width-md) {
  .container {
    & .sideViewWrapper {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;

      & .closeBtn {
        left: 275px;
        transition: left 200ms linear;
      }
    }

    & .sideViewWrapper.closed {
      & .closeBtn {
        left: 0;
      }
    }

    & .main {
      & .content {
        display: block;
        overflow: auto;
      }

      & .header {
        flex-direction: column-reverse;
        height: auto;

        & .headerLeft {
          width: 100%;
          height: 70px;
          flex: unset;
        }

        & .headerRight {
          width: 100%;
          height: 70px;
          flex: unset;
        }
      }
    }
  }
}

@media only screen and (--max-width-sm) {
  .container {
    height: unset;
    overflow: unset;
  }
}
