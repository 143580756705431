.item {
  display: inline-block;
  &:not(:last-child)::after {
    content: ', ';
  }
}
.tag {
  margin-left: var(--spacing0500);
}
.inlineList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing0500);
  align-items: center;
}
