.container {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing1000);
  gap: var(--spacing1000);
  width: 100%;
}
.infoBox {
  margin-bottom: unset;
}
.summaryFilters {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0500);
}

.popoverBtnWrapper {
  margin-left: 4px;
}
.receiptContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--gray200);
  gap: var(--spacing1000);
  list-style: none;
  padding: var(--spacing1000);
}

.item {
  padding: 0;
  list-style: none;
  border-radius: 2px;
  width: 250px;
  cursor: pointer;
  position: relative;
  & .flipInner {
    border: 5px solid var(--white);
    transition: border linear 80ms, transform 500ms ease-in-out;
  }
  &:hover {
    & .flipInner {
      border-color: var(--primaryBgLight);
    }
  }
  &.selected {
    & .flipInner {
      border-color: var(--primaryBg);
    }
  }
}

.noReceipt {
  display: flex;
  padding: var(--spacing1000);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing0500);
  background: var(--gray200);
}

.noReceiptIcon {
  font-size: var(--iconXL);
  color: var(--ink600);
}

.noReceiptText {
  composes: text4 from '~styles/typography/text.module.css';
}
