.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.title {
  padding: var(--spacing0750) var(--spacing1000);
}
