.pageContent {
  padding: 0;
  height: 100%;
  background: var(--white);
}

.container {
  border-bottom: none;
  height: 100%;
  & .tabsMenu {
    border-bottom: 1px solid var(--gray300);
    width: 824px;
    margin: auto;
  }

  & .navWrapper {
    margin-top: var(--spacing1000);
    text-align: center;
    margin-bottom: var(--spacing1000);
    padding: 0 var(--spacing1000);
  }
}

@media screen and (--max-width-md) {
  .container {
    & .tabsMenu {
      margin: 0 var(--spacing1000);
      width: calc(100% - 2 * var(--spacing1000));
      overflow: auto;
    }
  }
}

@media screen and (--max-width-sm) {
  .pageContent {
    height: auto;
  }
}
