.splitModesWrapper {
  display: flex;
  justify-content: center;
}

@media screen and (--max-width-sm) {
  .splitModesWrapper {
    display: block;
  }
}
