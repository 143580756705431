.dropzone {
  height: auto;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: var(--spacing2500);
  padding-bottom: var(--spacing2500);
  gap: var(--spacing1500);
  border: 1px dashed var(--gray600);
  box-sizing: border-box;
  position: relative;
  background: var(--gray300);
}

.dropzoneHasImage {
  background: var(--gray300);
  padding: var(--spacing1000);
}

.editor {
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--spacing0750);
}

.img {
  flex: 1;
  overflow: hidden;
  text-align: center;
}

.pdfPreview {
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
  height: 100%;
  flex: 1 1 0%;
}

.customCrop {
  &.ReactCrop__crop-selection {
    border: 1px dashed var(--gray600) !important;
  }
}

:global(.ReactCrop__crop-selection) {
  border: 1px dashed var(--gray600) !important;
}
.icon {
  font-size: 3rem;
  border-color: var(--primaryText);
  color: var(--primaryText);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0500);
}

.description {
  display: flex;
  justify-content: flex-end;
}

.helperText {
  display: flex;
  gap: var(--spacing0500);
  flex-direction: column;
  align-items: center;
}

.msg {
  display: flex;
  align-items: center;
  margin-top: var(--spacing0250);
  color: var(--ink500);
  & .msgIcon {
    font-size: var(--iconXS);
    margin-right: var(--spacing0500);
  }
  &.error {
    color: var(--blockingRed);
  }
}
