.percentileInput {
  width: 130px;
  padding: var(--spacing0500) 0;
  margin-bottom: 0;
}

@media screen and (--max-width-sm) {
  .percentileInput {
    width: 100%;
  }
}
