.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-bottom-color: var(--gray300);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  padding: var(--spacing1000);
  background: var(--white);
  & .headingWrapper {
    display: flex;
    justify-content: space-between;
    & .headingTagWrapper {
      display: flex;
      gap: var(--spacing1000);
    }
  }
}

.address {
  flex: 1;
  text-align: left;
  padding: var(--spacing0500) 0;
}

@media screen and (--max-width-sm) {
  .container {
    justify-content: center;
    & .headingWrapper {
      & .headingTagWrapper {
        flex-direction: column;
        gap: var(--spacing0500);
      }
    }
  }

  .address {
    margin: 0;
    flex: unset;
    text-align: left;
    border-left: 0;
  }
  .companyAdditionalInfo {
    margin-bottom: var(--spacing0500);
  }
  .childrenWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
  }

  .companyName {
    width: unset;
  }
}
