.remainderInfoWrapper {
  margin: var(--spacing1000) 0;
}
.recipient {
  white-space: nowrap;
  overflow: hidden;
  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-child {
      margin-bottom: var(--spacing0250);
    }
  }
}

.amountWrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing0250);
  justify-content: flex-end;
  width: 130px;
  & .remainderInfoIcon {
    font-size: var(--iconXS);
    color: var(--primaryText);
  }
}

.segmentField {
  margin-bottom: 0;
  width: 200px;
  padding: var(--spacing0500) 0;
}

.actionList {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--spacing1000);
  width: 88px;
}

.recipientWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing0250);
  padding: var(--spacing0750) 0;
  width: 110px;
  &.hasRecipient {
    width: 200px;
    padding: var(--spacing0250) 0;
  }
  & > * {
    white-space: nowrap;
  }
}

.itemTitle {
  margin: var(--spacing1000) 0;
}

.amountError {
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding-right: 112px;
}

.percentageError {
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding-right: 256px;
}

.itemTotal {
  display: flex;
  justify-content: flex-end;
  padding: var(--spacing0500) var(--spacing1000);
}

@media screen and (--max-width-lg) {
  .amountError {
    padding-right: var(--spacing1000);
  }

  .percentageError {
    padding-right: var(--spacing1000);
  }
}

@media screen and (--max-width-sm) {
  .segmentField {
    width: 100%;
  }

  .amountWrapper {
    width: 100%;
  }

  .actionList {
    width: 100%;
  }

  .recipientWrapper {
    width: 100%;
    &.hasRecipient {
      width: 100%;
    }
    & > * {
      white-space: wrap;
    }
  }
}
