.container {
  display: flex;
  flex-direction: row;
  gap: var(--spacing1000);
  justify-content: start;
  align-items: center;
  padding-top: var(--spacing1000);
  padding-bottom: var(--spacing1000);
  padding-left: var(--spacing0500);
  padding-right: var(--spacing0500);
  border: 1px solid var(--gray400);
  border-radius: var(--radiusS);
}

.imageWrapper {
  display: flex;
  flex: 0 0 48px;
  justify-content: center;
  max-height: 48px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: var(--spacing1000);
}

.noMarginBottom {
  margin-bottom: 0;
}
