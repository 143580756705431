.row {
  background-color: var(--bgColor);

  &.oddRow {
    --bgColor: var(--white);
  }

  &.evenRow {
    --bgColor: var(--gray100);
  }

  & .cell {
    background-color: var(--bgColor);
    height: 50px; /* is interpreted as a min-height on a td element */
    padding: 0 var(--spacing0500);
    text-align: left;

    &.center,
    &.center * {
      text-align: center;
    }

    &.right,
    &.right * {
      text-align: right;
    }

    &.isImportant {
      font-weight: bold;
    }

    & .cellContent {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      min-height: 50px;
    }

    &.topVerticalAlign {
      vertical-align: top;
      & .cellContent {
        min-height: unset;
      }
    }
  }

  &.bgTransparent {
    --bgColor: transparent;
  }

  & .actionsCell {
    position: sticky;
    right: 0;
    padding: 0;
  }

  &:not(.rowDetail) {
    & .cell {
      border-top: 1px solid var(--bgColor);
      border-bottom: 1px solid var(--bgColor);

      &:first-child {
        border-left: 5px solid var(--bgColor);
      }
    }

    &:hover .cell:first-child {
      border-left-color: var(--primaryBg);
    }

    &.error .cell:first-child {
      border-left-color: var(--blockingRed);
    }

    &.warning .cell:first-child {
      border-left-color: var(--alertOrange);
    }

    &.highlight .cell:first-child {
      border-left-color: var(--primaryBgDark);
    }

    &.isClickable {
      cursor: pointer;
    }
    &.notClickable {
      cursor: not-allowed;
    }

    &:hover {
      & .cell {
        border-top-color: var(--gray400);
        border-bottom-color: var(--gray400);
      }

      & .actions {
        visibility: visible;
        background: linear-gradient(
          to right,
          transparent 0,
          var(--bgColor) 100px
        );
      }
    }
  }
}

.actions {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 0;
  visibility: hidden;

  &.alwaysDisplayed {
    visibility: visible;
  }
}
