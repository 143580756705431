.item {
  position: relative;
  text-align: left;
  background-color: var(--white);
  border: 2px solid transparent;
  box-shadow: 0 0 1px var(--black020);
  transition: border-color ease-out 0.25s;

  &.isSelected {
    border-color: var(--primaryText);
  }
}

.field {
  padding: var(--spacing0750);
  border-bottom: 1px solid var(--gray300);

  &:last-of-type {
    border-bottom: none;
  }

  &.isHidden {
    display: none;
  }

  &.isHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.label {
  margin: 0 0 var(--spacing0500) 0;
  font-weight: bold;
}

.value {
  margin: 0;
}

.detailField {
  border-bottom: 1px solid var(--gray300);
}

.actionButton {
  width: 100%;
  margin: 0;
  justify-content: center;
  &:not(:first-of-type) {
    margin-top: var(--spacing0750);
  }
}

.selectInput {
  margin-left: 10px;
}
