.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.browseModal {
  padding: var(--spacing1500);
}
.msg {
  display: flex;
  align-items: center;
  margin-top: var(--spacing0250);
  color: var(--ink500);
  & .msgIcon {
    font-size: var(--iconXS);
    margin-right: var(--spacing0500);
  }
  &.error {
    color: var(--blockingRed);
  }
}
.segments {
  text-align: center;
  width: 100%;
}
.newReceiptContent {
  width: 650px;
}
