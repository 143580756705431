.section {
  margin-bottom: var(--spacing1000);
}

.infoBox {
  background: var(--gray300);
  font-weight: 600;
}

.btn {
  margin-right: 0.5rem;
}
