.container {
  & .wording {
    margin-bottom: var(--spacing0500);
  }

  & .field {
    flex: 1 0;
    margin-bottom: 0;
  }
}
.options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--spacing0500);
  align-items: center;
}
