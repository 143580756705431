.container {
  width: 100%;
  height: 315px;
  perspective: 1000px;
  background: var(--gray400);

  &.placeholder {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeload;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      var(--gray300) 8%,
      var(--gray400) 18%,
      var(--gray300) 33%
    );
    background-size: 1200px 104px;
    position: relative;
    box-shadow: 0 1px 5px var(--black020);
  }

  &:focus {
    outline: 1px dashed var(--ink500);
    outline-offset: 3px;
  }

  & .flipInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 500ms ease-in-out;
    transform-style: preserve-3d;
    box-shadow: 0 1px 5px var(--black020);
    will-change: transform;
    box-sizing: border-box;

    & .previewWrapper,
    & .detailsWrapper {
      background: var(--white);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      backface-visibility: hidden;
      will-change: transform;
    }

    & .previewWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & .previewInner {
        height: 280px;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        & .attached {
          background-color: var(--white);
          position: absolute;
          padding: 5px;
          color: var(--ink500);
          bottom: 10px;
          left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: var(--spacing1000);
          margin-left: var(--spacing1000);
        }
      }
      & .uploadDate {
        height: 35px;
        font-size: 12px;
        color: var(--ink500);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-shadow: 0 0 5px var(--black020);
      }
    }

    & .detailsWrapper {
      padding: 1rem;
      box-sizing: border-box;
      transform: rotateY(180deg);
      & .details {
        display: flex;
        flex-wrap: nowrap;
        & .content {
          flex: 1 0;
          margin-right: 0.5rem;
          overflow: hidden;
          & .amount {
            padding: 0;
          }
          & .location p {
            margin: 0;
          }
        }
        & .btns {
          display: flex;
          flex-direction: column;
          gap: var(--spacing0500);
        }
      }
    }
  }

  &.flipped .flipInner {
    transform: rotateY(180deg);
  }
}

.hidden {
  display: none;
}

@keyframes placeload {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
