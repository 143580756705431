.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing0750);
  width: 100%;
  & > * {
    flex: 1 0;
  }
}

.txTotalWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--spacing1000);
  padding: var(--spacing1000);
  background: var(--gray200);

  & .title {
    flex: 1 0;
  }

  & .totalAmount {
    text-align: right;
    margin-left: auto;
  }
}
